@import '../_variables';

a.product-list-item {
  text-decoration: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .product-list-item-title, .product-list-item-price {
        color: @color-accent-1;
      }
    }
  }
}

.product-list-item {
  position: relative;
  font-size: 16px;
  text-decoration: none;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .product-list-item-title {
    font-size: 16px;
  }

  .product-list-item-price {
    font-size: 19px;
    font-weight: bold;
    flex-shrink: 0;
  }

  .product-list-item-title, .product-list-item-price {
    transition: color @transition-duration @transition-curve;
  }

  img.product-list-item-photo {
    max-width: 100%;
    height: auto;
    min-height: 1px;
    object-fit: cover
  }

  img.product-list-item-photo,
  .aspect-shirt,
  .product-list-item-image-hover {
    position: relative;
    margin-bottom: .5rem;
  }

  .product-list-item-image-hover {
    position: relative;

    img.product-list-item-photo {
      margin-bottom: 0;
    }

    img.product-list-item-art {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: opacity @transition-duration @transition-curve;
      margin-bottom: .5rem;
      object-fit: cover;
    }
  }

  .product-list-item-colors {
    position: absolute;
    top: .5rem;
    right: .5rem;
    z-index: 2;
    display: flex;
    flex-direction: column;

    .product-list-item-color {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      margin-bottom: .25rem;
      border: 1px solid @color-default-1;

      @media (hover: hover) and (pointer: fine) {
        position: relative;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        transition: top .25s ease-in-out, opacity .25s ease-in-out, transform .25s ease-in-out;

        @item-color-levels: 1, 2, 3, 4, 5, 6;
        each(@item-color-levels, {
          &:nth-child(@{value}) {
            top: calc((@value - 1) * (-20px - .25rem + .5rem));
            z-index: 5 - @value;
            opacity: 1 - @value * .15;
            transform: scale(1 - @value * .1);
          }
        });
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: @color-default-4;
      text-decoration: none;

      .product-list-item-subtitle {
        color: @color-default-5;
      }

      .product-list-item-title, .product-list-item-price {
        color: @color-accent-1;
      }

      div.product-list-item-image-hover img.product-list-item-art {
        display: block;
      }

      .product-list-item-colors .product-list-item-color {
        @item-color-levels: 1, 2, 3, 4, 5, 6;
        each(@item-color-levels, {
          &:nth-child(@{value}) {
            top: 0;
            opacity: 1;
            transform: scale(1);
          }
        });
      }
    }
  }
}


// <editor-fold desc="SEALS">

.product-seal {
  text-align: center;
  display: flex;
  font-size: 17px;
  border-radius: @radius-default;
  overflow: hidden;
  padding: .25rem 0.5rem;
  color: @color-white;
  text-transform: uppercase;

  &.product-seal-primary, > .product-seal-primary {
    background: linear-gradient(to right, @color-primary-5, @color-primary-3);
  }

  &.product-seal-new {
    background: linear-gradient(to right, @color-info-5, @color-info-3);
  }
}

@media (max-width: 1200px) {
  .product-list .product-seal-promo > :not(:last-child) {
    display: none;
  }
}

// </editor-fold>